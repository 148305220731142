import React, { useState } from 'react'
import Breadcrumb from '../Component/BradeCrum'
import Product from '../Component/Product'
import HeaderMobile from '../Component/MobileHader'
import ProductBCrumb from '../Component/ProductBCrumb'


const allProdutUrl = {name : "Product Categories", url : "/All_Products"}

const ProductListing = () => {
  const [heading, setHeading] = useState("")

  return (
    <React.Fragment>
      <HeaderMobile/>
     {/* <Breadcrumb heading={heading} midTitle={allProdutUrl} bTitle={heading} /> */}
     <ProductBCrumb heading={heading} allProductLink={allProdutUrl} />
     <Product setHeading={setHeading} />
    </React.Fragment>
  )
}

export default ProductListing