import React, { useContext, useEffect, useState } from "react";
import { Navbar, Nav, Offcanvas, Container } from 'react-bootstrap';
import { CgMenuGridO } from "react-icons/cg";
import { Link } from "react-router-dom";
import { FaHome, FaBriefcase, FaShoppingCart,FaEnvelope  } from "react-icons/fa";
import { MdOutlineProductionQuantityLimits, MdMiscellaneousServices, MdNewReleases } from "react-icons/md";
import { GoProjectSymlink } from "react-icons/go";
import Dropdown from 'rsuite/Dropdown';
import 'rsuite/dist/rsuite.min.css';
import { GrGallery } from "react-icons/gr";
import { RiContactsBook2Fill } from "react-icons/ri";
import axios from "axios";

import Logo from "../assets/Img/logo-new.png";
import { ProductContext } from "../Context/ProductContext";
import { BCNContext } from "../Context/BreadcrumName";

const HeaderMobile = () => {
    const [show, setShow] = useState(false);
    const [productGroup, setProductGroup] = useState([]);
    const [services, setServices] = useState([]);

    const { item } = useContext(ProductContext);
    const { setBcnHeader } = useContext(BCNContext);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(
                    `${process.env.REACT_APP_GRIPHOLD_API_URL}/api/auth/getallcategory`
                );
                const serviceRes = await axios.get(
                    `${process.env.REACT_APP_GRIPHOLD_API_URL}/api/auth/allmanageservices`
                );
                setProductGroup(res.data);
                setServices(serviceRes.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <React.Fragment>
            <Navbar className="header-area mobileHeader" expand={false}>
                <Container fluid>
                    <Navbar.Brand href="/"><img className="mobileHadarImg" src={Logo} alt="logo" /></Navbar.Brand>
                    <div className="nav-right d-flex jsutify-content-end align-items-center">
            <Link to="/inquiry" className="sidebar-btn">
            <FaShoppingCart />
      </Link>
      <div className="quantity">
        {item.length}
      </div>
            
          </div>
                    <div className="mobileHaderIcon" onClick={handleShow}>
                        <CgMenuGridO />
                    </div>
                   
                </Container>
            </Navbar>

            <Offcanvas show={show} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton></Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav className="flex-column">
                        <div className="mobilehaderDiv">
                            <Link className="mobileHedarLink" to="/">
                                <FaHome /> Home
                            </Link>
                        </div>
                        <div className="mobilehaderDiv">
                            <Dropdown className="mobileHedarLink" title={<>  <FaEnvelope />About Us</>}>
                                <Dropdown.Item as={Link}   to="/aboutUs/About-Griphold">About Us</Dropdown.Item>
                                <Dropdown.Item as={Link} to="/Quality">Quality</Dropdown.Item>
                                <Dropdown.Item as={Link} to="/History">History</Dropdown.Item>
                                <Dropdown.Item as={Link} to="/Why-Griphold">Why Griphold</Dropdown.Item>
                                <Dropdown.Item as={Link} to="/Our_Network">Our Network</Dropdown.Item>
                                <Dropdown.Item as={Link} to="/customer">Customer</Dropdown.Item>
                                <Dropdown.Menu title="Sister Companies">
                                    <Dropdown.Item as="a" href="https://griportho.com/" target="_blank">
                                        Griportho Surgicals Pvt Ltd
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                                <Dropdown.Item as={Link} to="/Certification">Certification</Dropdown.Item>
                                <Dropdown.Item as={Link} to="/Download">Download</Dropdown.Item>
                            </Dropdown>
                        </div>
                        <div className="mobilehaderDiv">
                            <Dropdown className="mobileHedarLink" title={<><MdOutlineProductionQuantityLimits /> Products</>}>
                                {productGroup && productGroup.filter(item => item.IsActive === true).map(item => (
                                    <Dropdown.Item key={item._id} as={Link} to={`/productList/${item._id}`} onClick={() => setBcnHeader({name : item.categoryName, url : `/productList/${item._id}`})}>
                                        {item.categoryName}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown>
                        </div>
                        <div className="mobilehaderDiv">
                            <Link className="mobileHedarLink" to="/project">
                                <GoProjectSymlink /> Projects
                            </Link>
                        </div>
                        <div className="mobilehaderDiv">
                            <Dropdown className="mobileHedarLink" title={<><MdMiscellaneousServices /> Services</>}>
                                {services && services.map(item => (
                                    <Dropdown.Item key={item._id} as={Link} to={`/services/${item.Title}`}>
                                        {item.Title}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown>
                        </div>
                        <div className="mobilehaderDiv">
                            <Link className="mobileHedarLink" to="/What's-New">
                                <MdNewReleases /> What's New
                            </Link>
                        </div>
                        <div className="mobilehaderDiv">
                            <Link className="mobileHedarLink" to="/galleryList">
                                <GrGallery /> Gallery
                            </Link>
                        </div>
                        <div className="mobilehaderDiv">
                            <Link className="mobileHedarLink" to="/career">
                                <FaBriefcase /> Career
                            </Link>
                        </div>
                        <div className="mobilehaderDiv">
                            <Link className="mobileHedarLink" to="/contactUs">
                                <RiContactsBook2Fill /> Contact
                            </Link>
                        </div>
                    </Nav>
                </Offcanvas.Body>
            </Offcanvas>
        </React.Fragment>
    );
};

export default HeaderMobile;
