
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import "./css/style.css"
import "./css/animate.min.css";
import "./css/filter.css"
import "./css/product.css";
import "./css/jquery.fancybox.min.css"
import "./css/nice-select.css";
import "./css/swiper-bundle.min.css"
import "./css/bootstrap.min.css";
import "./css/price-filter.css"
import Home from './Page/Home';
import AnimatedCursor from "react-animated-cursor"
import { Link} from 'react-scroll';
import ProductListing from './Page/ProductListing';
import Hadar from './Component/Hader';
import Footer from './Component/Footer';
import ProductCategory from './Page/ProductCategory';
import ProducDetials from './Page/ProducDetials';
import Inquiry from './Page/Inquiry';
import AboutUs from './Page/AboutUs';
import ProductSubCategory from './Page/ProductSubCategory';
import ContactUs from './Page/ContactUs';
import GalleryList from './Page/GalleryList';
import GalleryDetails from './Page/GalleryDetails';
import WhatsNew from './Page/What\'sNew';
import Career from './Page/Career';
import Login from './Page/Login';
import Register from './Page/Register';
import Project from './Page/Project';
import ComanPage from './Page/ComanPage';

import Services from './Page/Services';
import 'react-image-lightbox/style.css';

import CustomerPage from './Page/CustomerPage';
import BranchOffice from './Page/BranchOffice';
import Distributors from './Page/Distributors';
import Dealers from './Page/Dealers';
import CMO from './Page/CMO';
import Credential from './Page/Credential';
import Certification from './Page/Certification';
import Download from './Page/Download';
import ServiceCom from './Page/ServiceCom';
import { GoChevronUp } from "react-icons/go";
import { useEffect, useState } from 'react';
import OurNetwork from './Page/OurNetwork';
import HeaderMobile from './Component/MobileHader';
import AllProducts from './Page/AllProducts';

function App() {

  const [showText, setShowText] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 100) { 
      setShowText(true);
    } else {
      setShowText(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const handleScrollTop=()=>{
    window.scrollTo(0, 0);
  }
  return (
    <div className="App">
       <AnimatedCursor
      innerSize={0}
      trailingSpeed={1}
      outerSize={25}
      color='255,255,255'
      outerAlpha={0.2}
      innerScale={0.7}
      outerScale={1}
      outerStyle={{
        border: '1px solid #ccc'
      }}
      showSystemCursor={true}
      clickables={[
        'a',
        'input[type="text"]',
        'input[type="email"]',
        'input[type="number"]',
        'input[type="submit"]',
        'input[type="image"]',
        'label[for]',
        'select',
        'textarea',
        'button',
       
      ]}
    />
    
    <Hadar />
    {/* <HeaderMobile /> */}
    
      <Routes>
    
        <Route  path="/" element={<Home />} />

        <Route  path="/productList/:productId" element={<ProductListing />} />
        <Route  path="/productCategory/:subCatId" element={<ProductCategory />} />
        <Route  path="/productSubCategory/:subsubCatId" element={<ProductSubCategory />} />

    
        <Route  path="/productDetails/:pId" element={<ProducDetials />} />
        <Route  path="/aboutUs/:title" element={<AboutUs />} />
        <Route  path="/inquiry" element={<Inquiry />} />
        <Route  path="/contactUs" element={<ContactUs />} />
        <Route  path="/galleryList" element={<GalleryList />} />
        <Route  path="/galleryDetails/:_id" element={<GalleryDetails />} />
        <Route  path="/What's-New" element={<WhatsNew />} />
        <Route  path="/career" element={<Career />} />
        <Route  path="/login" element={<Login />} />
        <Route  path="/register" element={<Register />} />
        <Route  path="/project" element={<Project />} />

//         <Route  path="/comanPage" element={<ComanPage />} />
        <Route  path="/services" element={<Services />} />
        <Route  path="/services/:titles" element={<ServiceCom />} />

        <Route  path="/:title" element={<ComanPage />} />
        <Route  path="/customer" element={<CustomerPage />} />
        <Route  path="/Branch_Office" element={<BranchOffice />} />
        <Route  path="/Distributors" element={<Distributors />} />
        <Route  path="/Dealers" element={<Dealers />} />
        <Route  path="/Central_Marketing_Office" element={<CMO />} />
        <Route  path="/Credential" element={<Credential />} />
        <Route  path="/Certification" element={<Certification />} />
        <Route  path="/Download" element={<Download />} />
        <Route  path="/Our_Network" element={<OurNetwork />} />

        <Route  path="/All_Products" element={<AllProducts />} />
        

        
        </Routes>
        <Footer />
    <h1 style={{display: showText ? "block" : "none"}} onClick={handleScrollTop} className='scroll-button'><GoChevronUp/></h1>
    </div>
  );
}

export default App;
