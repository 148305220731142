import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import Breadcrumb from "../Component/BradeCrum";
import HeaderMobile from "../Component/MobileHader";

const ServiceCom = () => {
  const { titles } = useParams();
  const location = useLocation();
  const [content, setContent] = useState({});
  const [title, setTitle] = useState("");

  useEffect(() => {
    let currentPath = location.pathname;
    currentPath = currentPath.split("/")[1];
    console.log("lplplplplplp", titles);
    setTitle(titles);
    axios
      .get(
        `${process.env.REACT_APP_GRIPHOLD_API_URL}/api/auth/getservices/${titles}`
      )
      .then(
        (res) => setContent(res.data)
        // console.log(res.data.data)
      )
      .catch((err) => console.log(err));
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <React.Fragment>
      <HeaderMobile/>
      <Breadcrumb heading={title} bTitle={title} />
      <Container className="galleryRow ">
        <div className="sub-title text-animation">
          <h6>
            {title}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={50}
              height={6}
              viewBox="0 0 50 6"
            >
              <path d="M50 3L45 0.113249V5.88675L50 3ZM0 3.5H45.5V2.5H0V3.5Z" />
            </svg>
          </h6>
        </div>
        {content && (
          <div dangerouslySetInnerHTML={{ __html: content.Description }}></div>
        )}
      </Container>
    </React.Fragment>
  );
};

export default ServiceCom;
