import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const Breadcrumb = ({heading,bTitle,midTitle}) => {
  const navigate = useNavigate();

  const handleNav=(nm)=>{
    navigate(nm)
  }
  return (
    <React.Fragment>
      <div className="breadcrumb">
        <div className="container-lg container-fluid d-flex justify-content-center">
          <Row className="row">
            <Col lg={12}>
              <div className="section-title six pt-130 mb-50">
                <h2 className="text-center">{heading?heading.split("-").join(" "):""}</h2>
                <div className="breadcrumb-section ">
                  <div className="banner-wrapper">
                    <ul className="breadcrumb-list">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      {midTitle && midTitle.name &&

                      <li onClick={()=>handleNav(midTitle.url)} style={{cursor:"pointer"}}>{midTitle?midTitle?.name?.split("-").join(" "):""}</li>
                      }
                      <li>{bTitle?bTitle.split("-").join(" "):""}</li>
                    </ul>
                  </div>
                </div>
                <div className="mt-5">

                
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Breadcrumb;
