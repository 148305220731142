import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import no_img from "../assets/Img/no-image.jpg";
import Breadcrumb from "../Component/BradeCrum";
import HeaderMobile from "../Component/MobileHader";
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css';

// Component to handle image fallback in case of error
const ImageWithFallback = ({ src }) => {
  const [imgSrc, setImgSrc] = useState(src);

  const handleError = () => {
    setImgSrc(no_img); // Fallback image if loading fails
  };

  return <img src={imgSrc} alt="img" onError={handleError} className="d-block w-100" />;
};

const GalleryDetails = () => {
  const [galleryData, setGalleryData] = useState([]); // Array to store gallery images
  const [isOpen, setIsOpen] = useState(false); // To track if the lightbox is open
  const [photoIndex, setPhotoIndex] = useState(0); // Track the index of the currently viewed image
  const { _id } = useParams();
  const [title, setTitle] = useState("");

  useEffect(() => {
    // Fetch gallery data based on category ID
    const fetchData = () => {
      axios
        .get(
          `${process.env.REACT_APP_GRIPHOLD_API_URL}/api/auth/get/gallery/by-cat-id/${_id}`
        )
        .then((res) => {
          const data = res.data;
          setTitle(data[0].Title);
          setGalleryData(data[0].GalleryImage); // Accessing GalleryImage directly
        })
        .catch((error) => {
          console.error(error);
        });
    };
    fetchData();
    window.scrollTo(0, 0);
  }, [_id]);

  // Preload image before opening the lightbox
  const openLightbox = (index) => {
    const img = new Image();
    const imageURL = `${process.env.REACT_APP_GRIPHOLD_API_URL}/${galleryData[index]}`;
    img.src = imageURL;

    img.onload = () => {
      setPhotoIndex(index);
      setIsOpen(true); // Open the lightbox after the image is preloaded
    };

    img.onerror = () => {
      console.error("Failed to load image", imageURL);
      setPhotoIndex(index); // Set index but avoid opening the lightbox until a valid image is loaded
    };
  };

  // Close the lightbox
  const closeLightbox = () => {
    setIsOpen(false);
  };

  // Move to next image in the lightbox
  const moveNext = () => {
    setPhotoIndex((photoIndex + 1) % galleryData.length);
  };

  // Move to previous image in the lightbox
  const movePrev = () => {
    setPhotoIndex((photoIndex + galleryData.length - 1) % galleryData.length);
  };

  return (
    <React.Fragment>
      <HeaderMobile />
      <Breadcrumb heading={title} bTitle={title} />
      <Container>
        <Row className="galleryRow">
          <div className="sub-title text-animation">
            <h6>
              Gallery
              <svg xmlns="http://www.w3.org/2000/svg" width={50} height={6} viewBox="0 0 50 6">
                <path d="M50 3L45 0.113249V5.88675L50 3ZM0 3.5H45.5V2.5H0V3.5Z" />
              </svg>
            </h6>
          </div>

          {/* Mapping through gallery images and displaying as thumbnails */}
          {galleryData.length > 0 &&
            galleryData.map((data, index) => (
              <Col lg={3} key={index}>
                {/* Thumbnail to open Lightbox */}
                <Link to="#" onClick={() => openLightbox(index)}>
                  <Card className="galleryCard">
                    <ImageWithFallback src={`${process.env.REACT_APP_GRIPHOLD_API_URL}/${data}`} />
                  </Card>
                </Link>
              </Col>
            ))}

          {/* Lightbox Modal */}
          {isOpen && galleryData.length > 0 && (
            <Lightbox
              mainSrc={`${process.env.REACT_APP_GRIPHOLD_API_URL}/${galleryData[photoIndex]}`}
              nextSrc={`${process.env.REACT_APP_GRIPHOLD_API_URL}/${
                galleryData[(photoIndex + 1) % galleryData.length]
              }`}
              prevSrc={`${process.env.REACT_APP_GRIPHOLD_API_URL}/${
                galleryData[(photoIndex + galleryData.length - 1) % galleryData.length]
              }`}
              onCloseRequest={closeLightbox}
              onMovePrevRequest={movePrev}
              onMoveNextRequest={moveNext}
            />
          )}
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default GalleryDetails;
