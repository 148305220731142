import React, { useContext, useEffect, useState } from "react";
import Breadcrumb from "../Component/BradeCrum";
import { Link, useParams } from "react-router-dom";
import { BsFilePdfFill } from "react-icons/bs";
import { Col, Row , Button } from "react-bootstrap";
import Category from "../Component/Category";
import InquiryModal from "../Component/InquiryModal";
import axios from "axios";
import no_img from "../assets/Img/no-image.jpg";
import { BCNContext } from "../Context/BreadcrumName";
import HeaderMobile from "../Component/MobileHader";
import ProductBCrumb from "../Component/ProductBCrumb";
import { CiFilter } from "react-icons/ci";
import { MdCancel } from "react-icons/md";

const allProdutUrl = { name: "Product Categories", url: "/All_Products" }

const ImageWithFallback = ({ src }) => {
  const [imgSrc, setImgSrc] = useState(src);

  const handleError = () => {
    setImgSrc(no_img);
  };

  return (
    <img
      src={imgSrc}
      alt='img'
      onError={handleError}
    />
  );
};

const ProductSubCategory = () => {
  const [subsubCategoryData, setSubSubCategoryData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [originalProductData, setOriginalProductData] = useState([]);
  const { subsubCatId } = useParams();
  const [minValue, setMinValue] = useState({});
  const [maxValue, setMaxValue] = useState({});
  const [minRangeValue, setMinRangeValue] = useState({});
  const [maxRangeValue, setMaxRangeValue] = useState({});
  const [specifications, setSpecifications] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [showCategory, setShowCategory] = useState(true);
  const [showDescription, setShowDescription] = useState(true);
  const [heading, setHeading] = useState("")
  const [visibleFilters, setVisibleFilters] = useState({});
  const { bcnHeader } = useContext(BCNContext);
  const [catDetail, setCatDetail] = useState({});
  const [subCatDetail, setSubCatDetail] = useState({});
  const [allData, setAllData] = useState([])
  const [productData2, setProductData2] = useState([]);
  const [filterData, setfilterData] = useState([])
  const [subCatID, setSubCatId] = useState("")

  const toggleVisibility = (key) => {
    setVisibleFilters(prevState => ({
      ...prevState,
      [key]: !prevState[key]
    }));
  };
  const handleDownload = async (p) => {
    let uurl = p.subCategoryDetails.productPDF;
    const url = `${process.env.REACT_APP_GRIPHOLD_API_URL}/${uurl}`;
    let dnFileName = uurl.split("/");
    dnFileName = dnFileName[dnFileName.length - 1];

    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      const anchor = document.createElement('a');
      anchor.href = blobUrl;
      anchor.download = `${dnFileName}.pdf`; // specify filename here
      anchor.click();

      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [subSubCategoryRes, filterRes] = await Promise.all([
          axios.get(
            `${process.env.REACT_APP_GRIPHOLD_API_URL}/api/auth/get/products-by-sub-sub-cat-id/${subsubCatId}`
          ),
          axios.get(
            `${process.env.REACT_APP_GRIPHOLD_API_URL}/api/auth/get/all/filter/${subsubCatId}`
          ),
        ]);

        const firstItem = subSubCategoryRes.data.data[0];
        setProductData(subSubCategoryRes.data.data);
        setOriginalProductData(subSubCategoryRes.data.data);

        const productRes = await axios.get(
          `${process.env.REACT_APP_GRIPHOLD_API_URL}/api/auth/get/products-by-sub-cat-id/${firstItem.subCategoryID}`
        );
        const products = productRes.data.data;
        const categoryRes = await axios.get(
          `${process.env.REACT_APP_GRIPHOLD_API_URL}/api/auth/all-sub-sub-category-byId/${subsubCatId}`
        );
        setHeading(categoryRes.data.data.productSubSubCategory)
        setProductData(products);
        console.log("categoryRes.data.data._id", categoryRes.data.data._id)
        console.log("firstItem",firstItem)
        const subCatid = categoryRes.data.data._id
        setSubCatId(firstItem.subCategoryID)

        const categoryDetailData = await axios.get(
          `${process.env.REACT_APP_GRIPHOLD_API_URL}/api/auth/Allsub-category/${categoryRes.data.data.subCategoryID}`
        );

        setSubCatDetail({ name: categoryDetailData.data.data.productSubCategory, url: `/productCategory/${categoryDetailData.data.data._id}` })
        const getCatDetail = await axios.get(
          `${process.env.REACT_APP_GRIPHOLD_API_URL}/api/auth/get/categories/${categoryRes.data.data.categoryID}`
        );
        setCatDetail({ name: getCatDetail.data.categoryName, url: `/productlist/${getCatDetail.data._id}` })
        console.log("getCatDetail", getCatDetail)


        const cleanedProducts = products.map((product) => {
          const updatedSpecifications = {};

          // Loop over each key in the specifications object
          for (let key in product.specifications) {
            // Check if the value is a string and contains commas
            if (typeof product.specifications[key] === 'string') {
              // Remove commas from the value
              updatedSpecifications[key] = product.specifications[key].replace(/,/g, '');
            } else {
              // If it's not a string or doesn't contain commas, leave it unchanged
              updatedSpecifications[key] = product.specifications[key];
            }
          }

          // Return a new product object with updated specifications
          return {
            ...product,
            specifications: updatedSpecifications,
          };
        });

        setProductData(cleanedProducts)
          // setOriginalProductData(products);
          setOriginalProductData(cleanedProducts);
          setAllData(cleanedProducts)
          setProductData2(cleanedProducts)

          console.log("filterRes.data.data", filterRes.data.data)

          const filterData = filterRes.data.data;
          setfilterData(filterData)
  
          console.log("filterData", filterData)
          // console.log("product", products)
  
  
  
          const specs = cleanedProducts.flatMap((product) =>
            Object.entries(product.specifications || {}).map(([key, value]) => ({
              key,
              value,
            }))
          );
  
          console.log("specs", specs)
  
          console.log("uooo", Array.from(
            new Set(specs.map((spec) => spec.key))
          ))
          const uniqueSpecs = Array.from(
            new Set(specs.map((spec) => spec.key))
          ).map((key) => {
            console.log("KEy ", key)
            const spec = specs.find((spec) => spec.key === key);
            console.log("specqq", spec)
            const filterType = filterData.find(
              (filter) => filter.FilterType[key] && filter.subCategoryID === firstItem.subCategoryID
            );
            
            console.log("filterType", filterType)
            return {
              key: spec.key,
              value: spec.value,
              filterType: filterType
                ? filterType.FilterType[spec.key]
                : "unknown",
            };
          });
          console.log(uniqueSpecs)
  
  
  
          uniqueSpecs.forEach((spec) => {
            if (spec.filterType === "range") {
              const specValues = cleanedProducts
                .map(
                  (p) =>
                    p.specifications &&
                    parseInt(p.specifications[spec.key], 10)
                )
                .filter(Boolean);
              if (specValues.length) {
                // console.log("specValues",specValues)
                const min = Math.min(...specValues);
                const max = Math.max(...specValues);
                // setRangeValue(spec.key, min, max);
                setMinValue((prev) => ({ ...prev, [spec.key]: min }));
                setMaxValue((prev) => ({ ...prev, [spec.key]: max }));
                setMinRangeValue((prev) => ({ ...prev, [spec.key]: min }));
                setMaxRangeValue((prev) => ({ ...prev, [spec.key]: max }));
              }
  
            }
          });
          
          setSpecifications(uniqueSpecs);
      }
       catch (error) {
        console.error("Error fetching data:", error);
      }
    };


    fetchData();
  }, [subsubCatId]);


  const handleSpecification = (products) => {
    console.log("appliedRangeFilters", appliedRangeFilters)
    console.log("appliedRangeFilters", appliedRangeFilters)
    console.log("filterData", filterData)
    console.log("product", products)
    setOriginalProductData(products);
    // setProductData2(products)
    // setProductData2(products)

    const specs = products.flatMap((product) =>
      Object.entries(product.specifications || {}).map(([key, value]) => ({
        key,
        value,
      }))
    );

    console.log("specs", specs)

    console.log("uooo", Array.from(
      new Set(specs.map((spec) => spec.key))
    ))
    const uniqueSpecs = Array.from(
      new Set(specs.map((spec) => spec.key))
    ).map((key) => {
      console.log("KEy ", key)
      const spec = specs.find((spec) => spec.key === key);
      console.log("specqq", spec)
      const filterType = filterData.find(
        (filter) => filter.FilterType[key] && filter.subCategoryID === subCatID
      );
      console.log("filterType", filterType)
      return {
        key: spec.key,
        value: spec.value,
        filterType: filterType
          ? filterType.FilterType[spec.key]
          : "unknown",
      };
    });
    console.log(uniqueSpecs)



    uniqueSpecs.forEach((spec) => {
      if (spec.filterType === "range") {
        const specValues = products
          .map(
            (p) =>
              p.specifications &&
              parseInt(p.specifications[spec.key], 10)
          )
          .filter(Boolean);
        if (specValues.length) {
          const min = Math.min(...specValues);
          const max = Math.max(...specValues);
          // setRangeValue(spec.key, min, max);
          setMinValue((prev) => ({ ...prev, [spec.key]: min }));
          setMaxValue((prev) => ({ ...prev, [spec.key]: max }));
          // setMinRangeValue((prev) => ({ ...prev, [spec.key]: min }));
          // setMaxRangeValue((prev) => ({ ...prev, [spec.key]: max }));
          setPendingMinValue((prev) => ({...prev, [spec.key]: min}));
          setPendingMaxValue((prev) => ({ ...prev, [spec.key]: max}));
          setPendingMinValue((prev) => ({...prev, [spec.key]: min}));
          setPendingMaxValue((prev) => ({ ...prev, [spec.key]: max}));
        }
      }
    });

    setSpecifications(uniqueSpecs);
  }

  useEffect(() => {
    console.log("apply filter caled")
    applyCombinedFilters()
    // applyCombinedFilters()
    // handleSpecification(productData, pro)
  }, [selectedFilters]);


  const handleFilterChange = (key, value) => {
    setSelectedFilters({ ...selectedFilters, [key]: value });
  };

  const removeFilter = (key) => {
    const { [key]: removedFilter, ...restFilters } = selectedFilters;
    // console.log("restFilters", restFilters)
    console.log("originalProductData", originalProductData)
    console.log("product", productData)
    console.log("product2", productData2)
    console.log(restFilters)
    setOriginalProductData(productData2)
    setSelectedFilters(restFilters);
  };

  const [appliedRangeFilters, setAppliedRangeFilters] = useState([]);

  const removeRangeFilter = (key) => {
    console.log("key", key);

    // Remove the filter from the applied range filters
    setAppliedRangeFilters((prev) => prev.filter((filter) => filter.key !== key));
    console.log("minRangeValue", minRangeValue)
    // Reset the min and max values for the given key to the initial range values
    setProductData(productData2); // Reset the product data to the original state
    handleSpecification(allData); // Recalculate specifications based on the reset data


    setMinValue((prev) => ({
      ...prev,
      [key]: minValue[key], // Reset to initial min value
    }));
    setMaxValue((prev) => ({
      ...prev,
      [key]: minValue[key], // Reset to initial max value
    }));

    // Update the state for minRangeValue and maxRangeValue
    setPendingMinValue((prev) => ({
      ...prev,
      [key]: minRangeValue[key], // Reset to the initial range min value
    }));
    setPendingMaxValue((prev) => ({
      ...prev,
      [key]: maxRangeValue[key], // Reset to the initial range max value
    }));

    // Reapply filters or reset the data to the original product data

    // Optionally update the selected filters to reflect the removed filter
    setSelectedFilters((prev) => {
      const updatedFilters = { ...prev };
      delete updatedFilters[key]; // Remove the filter for the specified key
      return updatedFilters;
    });

    console.log("Filter removed and ranges reset for key:", key);
  };
  const [pendingMinValue, setPendingMinValue] = useState({});
  const [pendingMaxValue, setPendingMaxValue] = useState({});



  // Handling pending changes to the range
  const handlePendingMinInputChange = (e, key) => {
    const value = parseInt(e.target.value, 10) || 0;
    setPendingMinValue((prev) => ({ ...prev, [key]: value }));
  };

  const handlePendingMaxInputChange = (e, key) => {
    const value = parseInt(e.target.value, 10) || 0;
    setPendingMaxValue((prev) => ({ ...prev, [key]: value }));
  };

  const handlePendingMinRangeChange = (e, key) => {
    const value = parseInt(e.target.value, 10) || 0;
    setPendingMinValue((prev) => ({ ...prev, [key]: value }));
  };

  const handlePendingMaxRangeChange = (e, key) => {
    const value = parseInt(e.target.value, 10) || 0;
    setPendingMaxValue((prev) => ({ ...prev, [key]: value }));
  };

  // useEffect(() => {
  //   applyFilters();
  // }, [selectedFilters, minValue, maxValue]);
  const [rangeValues, setRangeValues] = useState({ min: {}, max: {} });


  const applyFilters = () => {
    let filteredProducts = [...originalProductData];
    Object.entries(selectedFilters).forEach(([key, value]) => {
      if (value !== "") {
        filteredProducts = filteredProducts.filter(
          (product) =>
            product.specifications && product.specifications[key] === value
        );
      }
    });

    filteredProducts = filteredProducts.filter((product) => {
      return Object.keys(minValue).every(
        (key) =>
          (product.specifications &&
            parseInt(product.specifications[key])) >= minValue[key] &&
          (product.specifications &&
            parseInt(product.specifications[key])) <= maxValue[key]
      );
    });
    console.log("filteredProducts", filteredProducts)
    setProductData(filteredProducts);
    if (
      Object.keys(selectedFilters).length === 0 &&
      Object.keys(minValue).every((key) => minValue[key] === 0) &&
      Object.keys(maxValue).every(
        (key) => maxValue[key] === maxRangeValue[key]
      )
    ) {
      setShowDescription(true);
      setShowCategory(true);
    } else {
      setShowDescription(false);
      setShowCategory(false);
    }

  };


  const applyRangeFilter = (key) => {
    console.log(maxValue);

    setRangeValues((prev) => {
      // Get the current min and max values for the key from the existing rangeValues
      const currentMin = prev.min[key] ?? minRangeValue[key] ?? 0;
      const currentMax = prev.max[key] ?? maxRangeValue[key] ?? Infinity;

      // Calculate the updated min and max values
      const updatedMin = pendingMinValue[key] ?? currentMin;
      const updatedMax = pendingMaxValue[key] ?? currentMax;

      const updated = {
        ...prev,
        min: { ...prev.min, [key]: updatedMin },
        max: { ...prev.max, [key]: updatedMax },
      };

      console.log(updated);

      // Update the applied range filters
      setAppliedRangeFilters((prev) => {
        const existingFilterIndex = prev.findIndex((filter) => filter.key === key);

        const updatedFilters = [...prev];
        if (existingFilterIndex !== -1) {
          // Replace the filter if it exists
          updatedFilters[existingFilterIndex] = {
            key,
            min: updatedMin,
            max: updatedMax,
          };
        } else {
          // Add new filter if it doesn't exist
          updatedFilters.push({
            key,
            min: updatedMin,
            max: updatedMax,
          });
        }

        return updatedFilters;
      });

      return updated;
    });
  };



  useEffect(() => {
    console.log("vvvvv")
    applyCombinedFilters();
  }, [appliedRangeFilters]); // Run whenever appliedRangeFilters changes

  // const applyFiltersWithRange = (minVals, maxVals) => {
  //   // Start with the full original product data
  //   let filteredProducts = [...originalProductData];
  //   // setProductData2(filteredProducts)
  //   // setOriginalProductData(originalProductData)
  //   console.log("applyFiltersWithRange", minVals, maxVals);
  //   console.log(filteredProducts)
  //   setOriginalProductData(filteredProducts)
  //   setProductData2(filteredProducts)
  //   // Apply range filters
  //   filteredProducts = filteredProducts.filter((product) => {
  //     return Object.keys(minVals).every((key) => {
  //       if (!product.specifications || !product.specifications[key]) {
  //         return true; // Keep products without specifications
  //       }

  //       // Parse the specification value
  //       const specValue = parseInt(product.specifications[key], 10);

  //       // Handle cases where min and max values may not be set or are invalid
  //       const minVal = minVals[key] !== undefined ? minVals[key] : minRangeValue[key];
  //       const maxVal = maxVals[key] !== undefined ? maxVals[key] : maxRangeValue[key];

  //       // Apply the range check
  //       return specValue >= minVal && specValue <= maxVal;
  //     });
  //   });

  //   // Update the product data
  //   console.log("filteredProducts", filteredProducts)
  //   setProductData(filteredProducts);
  //   // handleSpecification(filteredProducts)
  //   // applyCombinedFilters()
  //   // Log the filtered products after the state update
  //   // You can verify the update in the useEffect hook instead
  // };

  const applyCombinedFilters = () => {
    // Start with the original product data
    let filteredProducts = [...allData];

    console.log("Original Product Data:", filteredProducts);
    console.log("Selected Filters:", selectedFilters);
    console.log("Applied Range Filters:", appliedRangeFilters);

    // Apply selected filters (normal filters)
    Object.entries(selectedFilters).forEach(([key, value]) => {
      if (value !== "") {
        filteredProducts = filteredProducts.filter((product) =>
          product.specifications && product.specifications[key] === value
        );
      }
    });

    console.log("After applying selected filters:", filteredProducts);

    // Apply range filters
    appliedRangeFilters.forEach(({ key, min, max }) => {
      filteredProducts = filteredProducts.filter((product) => {
        // console.log("-----------",product.specifications?.[key])
        const value = product.specifications?.[key];

        if (value === undefined || value === "") return
        if (value !== undefined) {
          console.log("product", product)
          console.log("Value", value)

          // Attempt to parse the specification value as a number
          const parsedValue = parseFloat(value.toString().replace(/[^\d.-]/g, ''));

          // Check if the parsed value is a valid number and apply the range filter
          if (!isNaN(parsedValue)) {
            return parsedValue >= min && parsedValue <= max;
          }
        }

        // Keep product if the value is not applicable or invalid
        return true;
      });
    });


    console.log("After applying range filters:", filteredProducts);

    // Handle the updated specifications (optional, if needed)
    handleSpecification(filteredProducts);

    // Update the state with the filtered products
    setProductData(filteredProducts);
    setProductData2(filteredProducts);

    // Show or hide description and category based on the filters applied
    if (
      Object.keys(selectedFilters).length === 0 &&
      Object.keys(minValue).every((key) => minValue[key] === minRangeValue[key]) &&
      Object.keys(maxValue).every((key) => maxValue[key] === maxRangeValue[key])
    ) {
      setShowDescription(true);
      setShowCategory(true);
    } else {
      setShowDescription(false);
      setShowCategory(false);
    }

    console.log("Final filtered products:", filteredProducts);
  };


  function ClearAll() {
    setProductData(allData)
    setSelectedFilters({})
    setAppliedRangeFilters([])
    setOriginalProductData(allData)
    setProductData2(allData)
    setMinValue(minRangeValue)
    setMaxValue(maxRangeValue)
    setPendingMaxValue(maxRangeValue)
    setPendingMinValue(minRangeValue)
    handleSpecification(allData)
  }

  const renderFilterComponent = (spec) => {
    if (spec.filterType === "range") {
      const minVal = minRangeValue[spec.key] || 0;
      const maxVal = maxRangeValue[spec.key] || 500;
      // console.log("minVal",minVal)

      const minPercentage = ((pendingMinValue[spec.key] || minVal) - minVal) / (maxVal - minVal) * 100;
      const maxPercentage = ((pendingMaxValue[spec.key] || maxVal) - minVal) / (maxVal - minVal) * 100;

      return (
        <div className="filter-item second-part mt-15" key={spec.key}>
          <span
            onClick={() => toggleVisibility(spec.key)}
            style={{ cursor: "pointer", marginLeft: "10px", display: 'block' }}
            className="h-100"
          > <p className="filter-item-inner-heading">
              {spec.key}

              {visibleFilters[spec.key] ? "▲" : "▼"}


            </p> </span>
          {visibleFilters[spec.key] && (
            <div>
              <div className="d-flex">
                <div className="price-wrapper">
                  <div className="price-input">
                    <div className="field">
                      <span>Min</span>
                      <input
                        type="number"
                        disabled
                        className="input-min w-50"
                        value={pendingMinValue[spec.key] || minVal}

                        onChange={(e) => handlePendingMinInputChange(e, spec.key)}
                      />
                    </div>
                    <div className="separator">-</div>
                    <div className="field">
                      <span>Max</span>
                      <input
                        disabled
                        type="number"
                        className="input-max w-50"
                        value={pendingMaxValue[spec.key] || maxVal}

                        onChange={(e) => handlePendingMaxInputChange(e, spec.key)}
                      />
                    </div>
                  </div>
                  <div className="slider">
                    <div className="progress"></div>
                  </div>
                  <div className="range-input">
                    <input
                      type="range"
                      className="range-min"
                      min={minVal}
                      max={maxVal}

                      onChange={(e) => handlePendingMinRangeChange(e, spec.key)}
                      value={pendingMinValue[spec.key] || minVal}

                      style={{ background: `linear-gradient(to right, white 0%, white ${minPercentage}%, #01458e ${minPercentage}%, #01458e ${maxPercentage}%, white ${maxPercentage}%, white 100%)` }}
                    />
                    <input
                      type="range"
                      className="range-max"
                      min={minVal}
                      max={maxVal}
                      value={pendingMaxValue[spec.key] || maxVal}

                      onChange={(e) => handlePendingMaxRangeChange(e, spec.key)}
                      style={{ background: `linear-gradient(to right, white 0%, white ${minPercentage}%, #01458e ${minPercentage}%, #01458e ${maxPercentage}%, white ${maxPercentage}%, white 100%)` }}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center mb-2">

                <button
                  onClick={() => applyRangeFilter(spec.key)}
                  style={{ marginTop: "20px", cursor: "pointer" }}
                  className="py-1 px-4 mb-2 apply-range-btn btn-hover"
                >
                  Apply
                </button>
              </div>
            </div>
          )}


        </div>
      );
    } else if (spec.filterType === "normal") {
      const values = originalProductData
        .map((product) =>
          product.specifications ? product.specifications[spec.key] : null
        )
        .filter((value, index, self) => value && self.indexOf(value) === index);

      return (
        <div className="filter-item third-part mt-15" key={spec.key}>
          <section className="filter-item-inner">
            <span
              onClick={() => toggleVisibility(spec.key)}
              style={{ cursor: "pointer", marginLeft: "10px", display: 'block' }}
              className="h-100"
            > <p className="filter-item-inner-heading">{spec.key}

                {visibleFilters[spec.key] ? "▲" : "▼"}

              </p> </span>
            {visibleFilters[spec.key] && (
              <div className="filter-attribute-list-inner numb-ser">
                <ul className="mt-2 filter-attribute-list ul-reset">
                  {/* <li className="filter-attribute-item">
                  <label className="filter-attribute-label ib-m">
                    <input
                      type="radio"
                      className="d-none"
                      name={spec.key}
                      value=""
                      onChange={() => removeFilter(spec.key)}
                      onChange={() => removeFilter(spec.key)}
                      checked={selectedFilters[spec.key] === ""}
                    />
                    All
                  </label>
                </li> */}
                  {values.sort().map((value, index) => (
                    <li key={index} className="filter-attribute-item">
                      <label className="filter-attribute-label ib-m">
                        <input
                          type="radio"
                          className="d-none"
                          name={spec.key}
                          value={value}
                          onChange={() => handleFilterChange(spec.key, value)}
                          checked={selectedFilters[spec.key] === value}
                        />
                        {value}
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            )}

          </section>
        </div>
      );
    }
    return null;
  };
  return (
    <React.Fragment>
      <HeaderMobile />
      {/* <Breadcrumb heading={heading} midTitle={bcnHeader} bTitle={heading}/> */}
      <ProductBCrumb heading={heading} midTitle={bcnHeader} bTitle={heading} allProductLink={allProdutUrl} pcList={subCatDetail} pList={catDetail} />
      <div className="container-fluid">
        <Row>
          <Col xl={4} lg={4} className="order-xl-1 order-1">
            <div className="shop-sidebar">
              <main className="main" role="main">
                <div className="wrapper cf">
                  <div className="sidebar">
                    <div className="pb-2 d-flex align-items-center" style={{ borderBottom: '2px solid gray' }}>
                      <h1 className="sidebar-heading flex-grow-1 mb-0 pb-0" style={{ lineHeight: '0px' }}>Filter Products</h1>
                      <h3 className="flex-shrink-0 mb-0 "><CiFilter /></h3>

                    </div>
                    {Object.keys(selectedFilters).length > 0 || appliedRangeFilters.length > 0 && (
                      <div className="d-flex mb-2 mt-4 align-items-center">
                        <h4 className="flex-grow-1 fs-5 ps-4">Applied Filters:</h4>
                        <Button
                          onClick={ClearAll}
                          className="button-reset"
                        >
                          <h6 className="flex-shrink-0">
                            Clear All Filter
                          </h6>
                        </Button>
                      </div>
                    )}
                    {appliedRangeFilters.length > 0 && (
                      <div className="col-lg-12 ">

                        <ul className="mb-0 ul-reset2">
                          {appliedRangeFilters.map(({ key, min, max }) => (
                            <li key={key}>
                              {`${key}: ${min} - ${max}`}
                              <button
                                className="btn cross btn-sm"
                                onClick={() => removeRangeFilter(key)}
                              >
                                <MdCancel />
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}


                    <ul className="filter ul-reset">
                      {specifications.map((spec) =>
                        renderFilterComponent(spec)
                      )}
                    </ul>
                  </div>
                </div>
              </main>
            </div>
          </Col>
          <Col xl={8} lg={8} className="order-xl-2 order-2">
            <Row className="gy-5 mb-70">

              <div className="col-lg-12">
                <h4>All Products</h4>
              </div>
              {productData?.map((p) => (
                <Col xl={4} lg={6} sm={6} className="mt-10" key={p._id}>
                  <div className="product-card">
                    <div className="product-card-img">
                      <Link to={`/productDetails/${p._id}`}>
                        {/* <img
                          src={`${process.env.REACT_APP_GRIPHOLD_API_URL}/${p.productImage}`}
                          alt={p.productName}
                        /> */}
                        <ImageWithFallback
                          src={p.productImage ? `${process.env.REACT_APP_GRIPHOLD_API_URL}/${p.productImage}` : no_img}
                        />
                      </Link>
                    </div>
                    <div className="product-card-content">
                      <h6>

                        <Link to={`/productDetails/${p._id}`}>

                          <span>{p.productName}</span>  {p.subTitle}
                        </Link>
                      </h6>
                      <Link
                        htmlFor="colour-attribute-1"
                        className="filter-attribute-label product-btn"
                        to="#"
                        onClick={() => handleDownload(p)}
                      >
                        <BsFilePdfFill className="bi-file-pdf-fill icon-pdf" />{" "}
                        Catalogue
                      </Link>
                      <InquiryModal datas={p} />
                      <ul className="ps-3 mt-4">
                        {Object.entries(p.specifications)
                          .slice(0, 3) // Take only the first 3 specifications
                          .map(([key, value], index) => (
                            <li key={index}>
                              <strong>{key}:</strong> {value}
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default ProductSubCategory;
