import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Breadcrumb from "../Component/BradeCrum";
import HeaderMobile from "../Component/MobileHader";

const OurNetwork = () => {
  const [content, setContent] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_GRIPHOLD_API_URL}/api/auth/get/allnetwork`)
      .then((res) => {
        console.log(res);
        setContent(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <React.Fragment>
      <HeaderMobile/>
      <Breadcrumb heading="Our Network" bTitle="Our Network" />
      <Container className="galleryRow ">
        {(!content || content.length === 0) && <h2>Content will be coming soon...</h2>}
        <ul>
          {Object.entries(content).filter((items)=>items[1].IsActive===true).map(([key, value]) => (
            <>
              <br />
              <h3
                style={{
                  backgroundColor: "#01458e",
                  color: "white",
                  padding: "5px 5px",
                  fontSize: "15px",
                }}
              >
                {value.heading}
              </h3>
              <Row>
                <Col
                  lg={4}
                  key={value}
                  style={{
                    padding: "8px 15px",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  Address :- {value.address}
                </Col>
                <Col
                  lg={4}
                  key={value}
                  style={{
                    padding: "8px 15px",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  Contact :- {value.contactnumber1}
                </Col>
                <Col
                  lg={4}
                  key={value}
                  style={{
                    padding: "8px 15px",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  Email :- {value.email1}
                </Col>
                {value.contactnumber2 &&
                 <Col
                 lg={4}
                 key={value}
                 style={{
                   padding: "8px 15px",
                   fontWeight: "bold",
                   fontSize: "15px",
                 }}
               >
                 Contact :- {value.contactnumber2}
               </Col>
              
                }
               {value.email2 &&
                <Col
                lg={4}
                key={value}
                style={{
                  padding: "8px 15px",
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
              >
                Email :- {value.email2}
              </Col>
               }
               
              </Row>
            </>
          ))}
        </ul>
      </Container>
    </React.Fragment>
  );
};

export default OurNetwork;
