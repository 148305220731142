import React from 'react'
import Logo from "../assets/Img/logo-new.png"
import Vector1 from "../assets/Img/footer-contact-vector1.png"
import Vector2 from "../assets/Img/footer-contact-vector2.png"
import Vector3 from "../assets/Img/footer-contact-vector3.png"
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <React.Fragment>
        <footer className="home5-footer">
  <div className="container-lg container-fluid">
    <div className="footer-top">
      <Row className=" justify-content-center mb-70">
        <Col lg={10} >
          <div className="contact-area">
          
            <h2 className="text-animation">LET’S TALK</h2>
            <div className="email-address">
              <Link to="mailto:career@gripholdexports.com?subject="><span className="__cf_email__">info@griphold.com</span></Link>
              <svg className="shape" width={172} height={199} viewBox="0 0 172 199" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.1673 5.5C15.1673 8.44552 17.5551 10.8333 20.5007 10.8333C23.4462 10.8333 25.834 8.44552 25.834 5.5C25.834 2.55448 23.4462 0.166667 20.5007 0.166667C17.5551 0.166667 15.1673 2.55448 15.1673 5.5ZM103 120L103.911 119.588L103.903 119.57L103.894 119.553L103 120ZM75 124.5L74.1376 123.994L75 124.5ZM171.5 194L162.298 187.024L160.858 198.481L171.5 194ZM19.6362 4.9973C10.6645 20.4253 0.860873 45.2967 0.663195 69.7919C0.56425 82.0527 2.87209 94.2585 8.93498 105.137C15.0039 116.026 24.8021 125.524 39.5785 132.407L40.4229 130.593C25.9992 123.876 16.5308 114.657 10.682 104.163C4.82715 93.6582 2.56624 81.8139 2.66313 69.8081C2.85712 45.7699 12.5035 21.2414 21.3651 6.0027L19.6362 4.9973ZM39.5785 132.407C54.2328 139.232 66.0014 142.92 75.3148 144.346C84.6212 145.772 91.545 144.949 96.4471 142.669C101.38 140.375 104.2 136.631 105.29 132.411C106.373 128.222 105.737 123.632 103.911 119.588L102.089 120.412C103.763 124.118 104.302 128.24 103.354 131.911C102.413 135.55 99.9953 138.813 95.6036 140.856C91.1808 142.913 84.6923 143.759 75.6176 142.369C66.5499 140.98 54.9685 137.368 40.4229 130.593L39.5785 132.407ZM103.894 119.553C102.024 115.812 97.6137 111.793 92.061 111.321C86.4002 110.841 79.9652 114.065 74.1376 123.994L75.8624 125.006C81.472 115.449 87.287 112.923 91.8919 113.314C96.6049 113.714 100.476 117.188 102.106 120.447L103.894 119.553ZM74.1376 123.994C66.8679 136.379 72.9093 151.603 88.4628 164.665C104.081 177.782 129.605 189.069 162.427 193.867L162.717 191.888C130.158 187.128 105.013 175.953 89.7491 163.134C74.4203 150.26 69.3661 136.074 75.8624 125.006L74.1376 123.994Z" fill="url(#paint0_linear_2426_166)" />
                <defs>
                  <linearGradient id="paint0_linear_2426_166" x1={108} y1="163.5" x2="8.50001" y2={17} gradientUnits="userSpaceOnUse">
                    <stop offset={0} />
                    <stop offset={1} stopOpacity={0} />
                  </linearGradient>
                </defs>
              </svg>
              <div className="vector1">
                <img src={Vector1} alt='logo' />
              </div>
              <div className="vector2">
                <img src={Vector2} alt='logo' />
              </div>
              <div className="vector3">
                <img src={Vector3} alt='logo' />
                <svg className="shape2" width={70} height={57} viewBox="0 0 70 57" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 29.5C3.5 26.3333 10.5 18.5 5 1" stroke="url(#paint0_linear_2432_186)" strokeWidth={2} />
                  <path d="M31 56.1196C35.5 51.453 43.5 38.4315 69 42.9312" stroke="url(#paint1_linear_2432_186)" strokeWidth={2} />
                  <path d="M18 40.5L42 12" stroke="url(#paint2_linear_2432_186)" strokeWidth={2} />
                  <defs>
                    <linearGradient id="paint0_linear_2432_186" x1="4.65047" y1="25.9403" x2="-4.46102" y2="21.9944" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#EC6219" offset={0} />
                      <stop offset="0.0001" />
                      <stop offset={1} stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id="paint1_linear_2432_186" x1="48.5313" y1="54.1487" x2="45.0878" y2="41.2227" gradientUnits="userSpaceOnUse">
                      <stop offset={0} />
                      <stop offset={1} stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id="paint2_linear_2432_186" x1="27.3918" y1="37.5066" x2="17.2892" y2="24.121" gradientUnits="userSpaceOnUse">
                      <stop offset={0} />
                      <stop offset={1} stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </div>
            <div className="footer-logo">
              <Link to="/"><img src={Logo} alt="footer-logo" className='footerLogo' /></Link>
            </div>
          </div>
        </Col>
      </Row>
      {/* <Row className="row">
        <Col lg={12} >
          <div className="footer-menu-wrap">
           
            <ul className="footer-menu">
              <li>
                <Link to="/aboutUs/About-Griphold">
                  About Us
                  <svg width={11} height={11} viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M10.9532 0.0585938L10.7354 1.14748C10.5496 2.07672 10.2769 3.53489 10.1863 5.1213C10.095 6.71893 10.1931 8.37925 10.7023 9.73705C10.8477 10.1249 10.6512 10.5572 10.2634 10.7026C9.87551 10.8481 9.4432 10.6516 9.29776 10.2637C8.68194 8.62153 8.59252 6.71935 8.68873 5.03573C8.72553 4.39179 8.79018 3.76993 8.86659 3.19447L1.53034 10.5307C1.23745 10.8236 0.762572 10.8236 0.469679 10.5307C0.176786 10.2378 0.176786 9.76295 0.469679 9.47006L7.82528 2.11446C7.18273 2.19925 6.48717 2.27186 5.77637 2.31171C4.07615 2.40705 2.18197 2.32292 0.70457 1.68975C0.323847 1.52658 0.147484 1.08567 0.31065 0.704951C0.473817 0.324229 0.914726 0.147865 1.29545 0.311032C2.44305 0.802862 4.04886 0.906228 5.69239 0.814067C7.31348 0.723165 8.86779 0.449612 9.86179 0.263237L10.9532 0.0585938Z" />
                  </svg>
                </Link>
              </li>
              <li>
                <Link to="/project">
                  Project
                  <svg width={11} height={11} viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M10.9532 0.0585938L10.7354 1.14748C10.5496 2.07672 10.2769 3.53489 10.1863 5.1213C10.095 6.71893 10.1931 8.37925 10.7023 9.73705C10.8477 10.1249 10.6512 10.5572 10.2634 10.7026C9.87551 10.8481 9.4432 10.6516 9.29776 10.2637C8.68194 8.62153 8.59252 6.71935 8.68873 5.03573C8.72553 4.39179 8.79018 3.76993 8.86659 3.19447L1.53034 10.5307C1.23745 10.8236 0.762572 10.8236 0.469679 10.5307C0.176786 10.2378 0.176786 9.76295 0.469679 9.47006L7.82528 2.11446C7.18273 2.19925 6.48717 2.27186 5.77637 2.31171C4.07615 2.40705 2.18197 2.32292 0.70457 1.68975C0.323847 1.52658 0.147484 1.08567 0.31065 0.704951C0.473817 0.324229 0.914726 0.147865 1.29545 0.311032C2.44305 0.802862 4.04886 0.906228 5.69239 0.814067C7.31348 0.723165 8.86779 0.449612 9.86179 0.263237L10.9532 0.0585938Z" />
                  </svg>
                </Link>
              </li>
              <li>
                <Link to="/career">
                  Career
                  <svg width={11} height={11} viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M10.9532 0.0585938L10.7354 1.14748C10.5496 2.07672 10.2769 3.53489 10.1863 5.1213C10.095 6.71893 10.1931 8.37925 10.7023 9.73705C10.8477 10.1249 10.6512 10.5572 10.2634 10.7026C9.87551 10.8481 9.4432 10.6516 9.29776 10.2637C8.68194 8.62153 8.59252 6.71935 8.68873 5.03573C8.72553 4.39179 8.79018 3.76993 8.86659 3.19447L1.53034 10.5307C1.23745 10.8236 0.762572 10.8236 0.469679 10.5307C0.176786 10.2378 0.176786 9.76295 0.469679 9.47006L7.82528 2.11446C7.18273 2.19925 6.48717 2.27186 5.77637 2.31171C4.07615 2.40705 2.18197 2.32292 0.70457 1.68975C0.323847 1.52658 0.147484 1.08567 0.31065 0.704951C0.473817 0.324229 0.914726 0.147865 1.29545 0.311032C2.44305 0.802862 4.04886 0.906228 5.69239 0.814067C7.31348 0.723165 8.86779 0.449612 9.86179 0.263237L10.9532 0.0585938Z" />
                  </svg>
                </Link>
              </li>
              <li>
                <Link to="/What's-New">
                  What's New
                  <svg width={11} height={11} viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M10.9532 0.0585938L10.7354 1.14748C10.5496 2.07672 10.2769 3.53489 10.1863 5.1213C10.095 6.71893 10.1931 8.37925 10.7023 9.73705C10.8477 10.1249 10.6512 10.5572 10.2634 10.7026C9.87551 10.8481 9.4432 10.6516 9.29776 10.2637C8.68194 8.62153 8.59252 6.71935 8.68873 5.03573C8.72553 4.39179 8.79018 3.76993 8.86659 3.19447L1.53034 10.5307C1.23745 10.8236 0.762572 10.8236 0.469679 10.5307C0.176786 10.2378 0.176786 9.76295 0.469679 9.47006L7.82528 2.11446C7.18273 2.19925 6.48717 2.27186 5.77637 2.31171C4.07615 2.40705 2.18197 2.32292 0.70457 1.68975C0.323847 1.52658 0.147484 1.08567 0.31065 0.704951C0.473817 0.324229 0.914726 0.147865 1.29545 0.311032C2.44305 0.802862 4.04886 0.906228 5.69239 0.814067C7.31348 0.723165 8.86779 0.449612 9.86179 0.263237L10.9532 0.0585938Z" />
                  </svg>
                </Link>
              </li>
              <li>
                <Link to="/galleryList">
                  Gallery
                  <svg width={11} height={11} viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M10.9532 0.0585938L10.7354 1.14748C10.5496 2.07672 10.2769 3.53489 10.1863 5.1213C10.095 6.71893 10.1931 8.37925 10.7023 9.73705C10.8477 10.1249 10.6512 10.5572 10.2634 10.7026C9.87551 10.8481 9.4432 10.6516 9.29776 10.2637C8.68194 8.62153 8.59252 6.71935 8.68873 5.03573C8.72553 4.39179 8.79018 3.76993 8.86659 3.19447L1.53034 10.5307C1.23745 10.8236 0.762572 10.8236 0.469679 10.5307C0.176786 10.2378 0.176786 9.76295 0.469679 9.47006L7.82528 2.11446C7.18273 2.19925 6.48717 2.27186 5.77637 2.31171C4.07615 2.40705 2.18197 2.32292 0.70457 1.68975C0.323847 1.52658 0.147484 1.08567 0.31065 0.704951C0.473817 0.324229 0.914726 0.147865 1.29545 0.311032C2.44305 0.802862 4.04886 0.906228 5.69239 0.814067C7.31348 0.723165 8.86779 0.449612 9.86179 0.263237L10.9532 0.0585938Z" />
                  </svg>
                </Link>
              </li>
              <li>
                <Link to="/contactUs">
                  Contact Us
                  <svg width={11} height={11} viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M10.9532 0.0585938L10.7354 1.14748C10.5496 2.07672 10.2769 3.53489 10.1863 5.1213C10.095 6.71893 10.1931 8.37925 10.7023 9.73705C10.8477 10.1249 10.6512 10.5572 10.2634 10.7026C9.87551 10.8481 9.4432 10.6516 9.29776 10.2637C8.68194 8.62153 8.59252 6.71935 8.68873 5.03573C8.72553 4.39179 8.79018 3.76993 8.86659 3.19447L1.53034 10.5307C1.23745 10.8236 0.762572 10.8236 0.469679 10.5307C0.176786 10.2378 0.176786 9.76295 0.469679 9.47006L7.82528 2.11446C7.18273 2.19925 6.48717 2.27186 5.77637 2.31171C4.07615 2.40705 2.18197 2.32292 0.70457 1.68975C0.323847 1.52658 0.147484 1.08567 0.31065 0.704951C0.473817 0.324229 0.914726 0.147865 1.29545 0.311032C2.44305 0.802862 4.04886 0.906228 5.69239 0.814067C7.31348 0.723165 8.86779 0.449612 9.86179 0.263237L10.9532 0.0585938Z" />
                  </svg>
                </Link>
              </li>
            </ul>
          </div>
        </Col>
      </Row> */}
    </div>
    <div className="footer-btm">
      <div className="copyright-area">
        <p>
          Copyright @ 2024 <Link to="#">Griphold</Link> All Rights Reserved
        </p>
      </div>
      <div className="terms-condition">
        <p>Developed by <Link to="https://www.barodaweb.com/" target="_blank">Barodaweb | The E-Catelogue Designer</Link></p>
      </div>
    </div>
  </div>
</footer>

    </React.Fragment>
  )
}

export default Footer