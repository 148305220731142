import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { IoMdAdd } from "react-icons/io";
import { useSpring, animated } from '@react-spring/web';
import WorkImg1 from "../assets/Img/work-portfolio-1.jpg"
import WorkImg2 from "../assets/Img/work-portfolio-2.jpg"
import WorkImg3 from "../assets/Img/work-portfolio-3.jpg"
import WorkImg4 from "../assets/Img/work-portfolio-4.jpg"
import WorkImg5 from "../assets/Img/work-portfolio-5.jpg"
import axios from 'axios';

const GallerySection = () => {
  const [data,setData]=useState([])

  useEffect(()=>{
  
    const fetchData = () =>{
      axios.get(`${process.env.REACT_APP_GRIPHOLD_API_URL}/api/auth/get/all/gallery/category`)
      .then((res)=>{
        console.log('gallery',res.data)
        setData(res.data)
      })
      .catch((error)=>{
        console.error(error)
      })
    }
    fetchData();
  },[])

  const titleProps = useSpring({
    from: { opacity: 0, transform: 'translateY(-20px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    config: { duration: 1000 },
  });
  return (
    <React.Fragment>
       <div className="work-section">
  <Container >
    <Row >
      <Col lg={12} >
        <div className="section-title-2 scroll-sec-title text-center ">
          <animated.h2 style={titleProps}>
                    <h2>
            Gallery
            {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
              <g>
                <path d="M50 20.7692H35.2015L45.6777 10.293L39.6703 4.32234L29.2308 14.7985V0H20.7692V14.7985L10.293 4.32234L4.32234 10.293L14.7985 20.7692H0V29.2308H14.7985L4.32234 39.6703L10.293 45.6777L20.7692 35.2015V50H29.2308V35.2015L39.6703 45.6777L45.6777 39.6703L35.2015 29.2308H50V20.7692Z" />
              </g>
            </svg> */}
          </h2>
          </animated.h2>  
        </div>
      </Col>
    </Row>
    <Row className=" mb-50">
      <Col lg={12} className="col-lg-12">
        <Row className=" mb-50 g-lg-0 gy-5 justify-content-evenly ">
          <Col lg={5} md={5} >
          {data[0] && (

            <div className="single-work magnetic-item">
              <div className="work-img">
                <img className='workImg' src={`${process.env.REACT_APP_GRIPHOLD_API_URL}/${data[0].GalleryImage}`} alt='img' />
              </div>
              <div className="work-content">
                <div className="content-wrap">
                  <h5><Link to="#">{data[0].Title}</Link></h5>
                  <div className="view-details-wrap">
                    <Link to={`/galleryDetails/${data[0]._id}`} className="active">
                      <span>
                        View
                        Details
                      </span> <span><IoMdAdd className="bi bi-plus" /></span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
          </Col>
          <Col lg={5} md={5} >
          {data[1] && (

            <div className="single-work magnetic-item">
              <div className="work-img">
              <img className='workImg' src={`${process.env.REACT_APP_GRIPHOLD_API_URL}/${data[1].GalleryImage}`} alt='img' />
              </div>
              <div className="work-content">
                <div className="content-wrap">
                  <h5><Link to="#">{data[1].Title}</Link></h5>
                  <div className="view-details-wrap">
                    <Link to={`/galleryDetails/${data[1]._id}`} className="active">
                      <span>
                        View
                        Details
                      </span> <span><IoMdAdd className="bi bi-plus" /></span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
          </Col>
        </Row>
        <Row className=" mb-80 g-lg-0 gy-5 justify-content-evenly">
          <Col lg={5} md={5} >
          {data[2] && (

            <div className="single-work magnetic-item">
              <div className="work-img">
              <img className='workImg' src={`${process.env.REACT_APP_GRIPHOLD_API_URL}/${data[2].GalleryImage}`} alt='img' />
              </div>
              <div className="work-content">
                <div className="content-wrap">
                  <h5><Link to="#">{data[2].Title}</Link></h5>
                  <div className="view-details-wrap">
                    <Link to={`/galleryDetails/${data[2]._id}`} className="active">
                      <span>View Details</span>
                      <span><IoMdAdd className="bi bi-plus" /></span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
          </Col>
        </Row>
        <Row className="row  g-lg-0 gy-5 justify-content-evenly">
          <Col lg={5} md={5} >
          {data[3] && (

            <div className="single-work magnetic-item">
              <div className="work-img">
              <img className='workImg' src={`${process.env.REACT_APP_GRIPHOLD_API_URL}/${data[3].GalleryImage}`} alt='img' />
              </div>
              <div className="work-content">
                <div className="content-wrap">
                  <h5><Link to="#">{data[3].Title}</Link></h5>
                  <div className="view-details-wrap">
                    <Link to={`/galleryDetails/${data[3]._id}`} className="active">
                      <span>
                        View
                        Details
                      </span> <span><IoMdAdd className="bi bi-plus" /></span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
          </Col>
          <Col lg={5} md={5} >
          {data[4] && (

            <div className="single-work magnetic-item">
              <div className="work-img">
              <img className='workImg' src={`${process.env.REACT_APP_GRIPHOLD_API_URL}/${data[4].GalleryImage}`} alt='img' />
              </div>
              <div className="work-content">
                <div className="content-wrap">
                  <h5><Link to="#">{data[4].Title}</Link></h5>
                  <div className="view-details-wrap">
                    <Link to={`/galleryDetails/${data[4]._id}`} className="active">
                      <span>
                        View
                        Details
                      </span> <span><IoMdAdd className="bi bi-plus" /></span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
          </Col>
        </Row>
      </Col>
    </Row>
    <div className="row">
      <div className="col-lg-12 d-flex align-items-center justify-content-between gap-4 flex-wrap text-animation">
        <div className="section-title scroll-sec-title-end our-work-section">
          <h2>
            Gallery
            {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
              <g>
                <path d="M50 20.7692H35.2015L45.6777 10.293L39.6703 4.32234L29.2308 14.7985V0H20.7692V14.7985L10.293 4.32234L4.32234 10.293L14.7985 20.7692H0V29.2308H14.7985L4.32234 39.6703L10.293 45.6777L20.7692 35.2015V50H29.2308V35.2015L39.6703 45.6777L45.6777 39.6703L35.2015 29.2308H50V20.7692Z" />
              </g>
            </svg> */}
          </h2>
          <p>Discover the excitement of our recent events and celebrations.</p>
        </div>
        <div className="btn_wrapper">
          <Link className="circle-btn btn-hover" to="/galleryList">
            <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} viewBox="0 0 12 12">
              <path d="M10.0035 3.40804L1.41153 12L0 10.5885L8.59097 1.99651H1.01922V0H12V10.9808H10.0035V3.40804Z">
              </path>
            </svg> Explore <strong>Now</strong>
            <span />
          </Link>
        </div>
      </div>
    </div>
  </Container>
</div>

    </React.Fragment>
  )
}

export default GallerySection