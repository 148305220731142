import React, { useEffect } from 'react'
import Hadar from '../Component/Hader'
import BanerSection from '../Component/BanerSection'
import CompanyDetails from '../Component/CompanyDetails'
import Services from '../Component/Services'
import ProductListing from '../Component/ProductListing'
import GallerySection from '../Component/GallerySection'
import LatestProduct from '../Component/LatestProduct'
import AwordSlider from '../Component/AwordSlider'
import Distributer from '../Component/Distributer'
import Follows from '../Component/Follows'
import Footer from '../Component/Footer'
import ProductAddModal from '../Component/ProductAddModal'
import HeaderMobile from '../Component/MobileHader'
import ServiceNew from '../Component/ServiceNew'



const Home = () => {

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
  return (
    <React.Fragment>
   <HeaderMobile />
        {/* <Hadar/> */}
        {/* <div className='page-content'> */}
        <BanerSection />
        <CompanyDetails />
        {/* <ServiceNew /> */}
        <Services />
        {/* </div> */}
        <ProductListing />
        <GallerySection />
        <LatestProduct />
        <AwordSlider />
        {/* <div className='page-content'>
        <div className="line_wrap">
        <div className="line_item first-line" />
        <div className="line_item center-line" />
        <div className="line_item last-line" />
      </div> */}
        {/* <Distributer /> */}
        <ProductAddModal />
        <Follows />
        {/* <Footer /> */}
         {/* </div> */}
    </React.Fragment>
  )
}

export default Home