import React, { useState } from 'react';
import About2 from "../assets/Img/about-2.jpg";
import About1 from "../assets/Img/about-1.jpg";
import { Link as RouterLink } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import CountUp from 'react-countup';
import { useSpring, animated } from '@react-spring/web';
import VisibilitySensor from 'react-visibility-sensor';
import { FaArrowRight } from "react-icons/fa6";

const CompanyDetails = () => {
  const titleProps = useSpring({
    from: { opacity: 0, transform: 'translateY(-20px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    config: { duration: 1500 },
  });

  const descriptionProps = useSpring({
    from: { opacity: 0, transform: 'translateY(20px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    delay: 500,
    config: { duration: 1500 },
  });

  const [viewed, setViewed] = useState({
    branch: false,
    distributor: false,
    dealer: false,
  });

  const onVisibilityChange = (isVisible, key) => {
    if (isVisible && !viewed[key]) {
      setViewed((prevViewed) => ({ ...prevViewed, [key]: true }));
    } else if (!isVisible && viewed[key]) {
      setViewed((prevViewed) => ({ ...prevViewed, [key]: false }));
    }
  };

  return (
    <React.Fragment>
      <div className="home1-about-section mb-50 mob-mt-30 backgroudImg">
     
        
        <div className="container-lg container-fluid">
        <div className='shapeDiv'>
            <div className='shapeSubDiv'>
              <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 333 134"
                          fill="none"
                        >
                          <path
                            d="M22.3329 3C22.3329 4.47276 23.5268 5.66667 24.9995 5.66667C26.4723 5.66667 27.6662 4.47276 27.6662 3C27.6662 1.52724 26.4723 0.333333 24.9995 0.333333C23.5268 0.333333 22.3329 1.52724 22.3329 3ZM24.9998 112L25.1494 112.477L25.1571 112.475L25.1647 112.472L24.9998 112ZM24.9999 131L20.285 127.668L19.7568 133.417L24.9999 131ZM331.435 4.94405L331.27 4.47202L331.435 4.94405ZM24.9995 3.5H331.105V2.5H24.9995V3.5ZM331.27 4.47202L24.8349 111.528L25.1647 112.472L331.6 5.41607L331.27 4.47202ZM24.8501 111.523C18.739 113.44 13.4156 115.362 9.36195 117.221C7.33543 118.15 5.61514 119.068 4.26847 119.969C2.93072 120.863 1.9208 121.766 1.36259 122.678C0.786949 123.619 0.67813 124.604 1.22066 125.539C1.73254 126.422 2.77982 127.176 4.29278 127.839C7.33352 129.17 12.5855 130.267 20.4687 131.086L20.572 130.091C12.6991 129.274 7.58161 128.187 4.69384 126.923C3.24255 126.287 2.43581 125.641 2.08569 125.037C1.76621 124.487 1.78741 123.9 2.21557 123.2C2.66116 122.472 3.52883 121.666 4.82424 120.8C6.11074 119.94 7.77918 119.046 9.77874 118.13C13.7773 116.296 19.0554 114.389 25.1494 112.477L24.8501 111.523ZM331.105 3.5C331.668 3.5 331.802 4.28631 331.27 4.47202L331.6 5.41607C333.195 4.85893 332.794 2.5 331.105 2.5V3.5Z"
                            fill="#2F2F2F"
                          />
                        </svg>
                        </div>
                        </div>
          <Row className="g-4 mb-60">
            {/* <Col lg={4} md={4}>
              <div className="sub-title two text-animation">
                <div className="section-title text-animation">
                 
              </div>
            </Col> */}
           
            <Col lg={12} md={12}>
              <div className="section-title whoWeTitle two text-animation">
                
              <animated.h2 style={titleProps}>
                  <h2>
                    Who We Are
                  </h2>
               
                  </animated.h2>
                
                  
                {/* </div> */}
              <animated.h2 style={titleProps}>
                <h2>GRIPHOLD ENGINEERING PVT. LTD.</h2>
                </animated.h2>
              </div>
             
            </Col>
           
          </Row>
          <Row>
            {/* <Col lg={4} md={6} className="d-lg-flex justify-content-end align-items-start">
              <div className="about-left animet-images magnetic-item pt-30">
                <img src={About2} alt='img' />
              </div>
            </Col> */}
            <Col lg={6} md={6}>
              <div className="about-right-img animet-images magnetic-item mob-mt-30">
                <img src={About1} alt='img' />
              </div>
            </Col>
            <Col lg={6} md={6} className="md-mt-30 mob-mt-30">
              <div className="about-content text-animation">
                <animated.p style={descriptionProps}>
                <p>Intelligent Tooling with a Global Outlook, Griphold Engineering Pvt. Ltd. is a world-class provider of an expansive array of bolting solutions. Our broad range of torque tools caters to the equipment needs of a gamut of sectors. With its stronghold on quality and technology the company has earned a sound reputation and loyal customer base globally.</p>
                <p>As a market-leading bolting solutions company, we have the depth and breadth of expertise to respond to the most technically challenging and time-sensitive projects.</p>
                </animated.p>
                <RouterLink to="/aboutUs/About-Griphold" className='aboutMore'>
                  About More
                  <FaArrowRight/>
                  {/* <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} viewBox="0 0 12 12">
                    <path d="M10.0035 3.40804L1.41153 12L0 10.5885L8.59097 1.99651H1.01922V0H12V10.9808H10.0035V3.40804Z" />
                  </svg> */}
                </RouterLink>
              </div>
            </Col>
          </Row>
          <ul className="counter-wrap">
            <li className="single-counter" style={{width:"13%"}}>
              <VisibilitySensor onChange={(isVisible) => onVisibilityChange(isVisible, 'branch')} delayedCall>
                <div className="content">
                  <div className="number">
                    <h2 className="counter">
                      {viewed.branch && (
                        <CountUp
                          start={0}
                          end={423}
                          duration={3}
                        />
                      )}+
                    </h2>
                  </div>
                  <p>Branch Offices</p>
                </div>
              </VisibilitySensor>
            </li>
            <li className="single-counter">
              <VisibilitySensor onChange={(isVisible) => onVisibilityChange(isVisible, 'distributor')} delayedCall>
                <div className="content">
                  <div className="number">
                    <h2 className="counter">
                      {viewed.distributor && (
                        <CountUp
                          start={0}
                          end={240}
                          duration={3}
                        />
                      )}+
                    </h2>
                  </div>
                  <p>Distributors</p>
                </div>
              </VisibilitySensor>
            </li>
            <li className="single-counter">
              <VisibilitySensor onChange={(isVisible) => onVisibilityChange(isVisible, 'dealer')} delayedCall>
                <div className="content">
                  <div className="number">
                    <h2 className="counter">
                      {viewed.dealer && (
                        <CountUp
                          start={0}
                          end={365}
                          duration={3}
                        />
                      )}+
                    </h2>
                  </div>
                  <p>Dealers</p>
                </div>
              </VisibilitySensor>
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CompanyDetails;
