import React, { useEffect, useState } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { Link  } from 'react-router-dom';
import axios from 'axios';
import no_img from "../assets/Img/no-image.jpg";
import { FaArrowRight } from 'react-icons/fa';

const ImageWithFallback = ({ src }) => {
  const [imgSrc, setImgSrc] = useState(src);

  const handleError = () => {
    setImgSrc(no_img);
  };

  return (
    <img 
      src={imgSrc} 
      alt="img" 
      onError={handleError}
      className="our-product-img"
    />
  );
};

const ProductListing = () => {
  const [product, setProduct] = useState([]);
  const [showAll, setShowAll] = useState(false); // State to control show more/less functionality

  // Fetch products on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_GRIPHOLD_API_URL}/api/auth/getallcategory`);
        console.log("data==>", res.data);
        setProduct(res.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Show 6 products initially, or up to 10 if "showAll" is true
  const visibleProducts = showAll ? product.slice(0, product.length) : product.slice(0, 3);

  return (
    <React.Fragment>
      <div className="home2-portfolio-section">
        <div className="container-lg container-fluid">
          <Row className="mb-60">
            <Col lg={12} className='productCol'>
              <div className="section-title text-animation">
                <h2>Our Products</h2>
                {/* <div className="dash-and-paragraph three">
                  <div className="dash" />
                </div> */}
              </div>
              
          
              {product.length > 3 && ( // Show the button only if there are more than 6 products
                <button    className="hideShowBtn" onClick={() => setShowAll(!showAll)}>
                  {showAll ? 'Show Less' : 'Show All Products'} <FaArrowRight/>
                </button>
              )}
          
            </Col>
          </Row>
          <Row className="gy-lg-5 g-4 justify-content-between">
            {visibleProducts?.map((d) => (
              <Col lg={4} md={6} key={d._id}>
                <div className="portfolio-card magnetic-item">
                  <div className="image-and-tag">
                    <div className="portfolio-img">
                      <ImageWithFallback 
                        src={d.categoryImage ? `${process.env.REACT_APP_GRIPHOLD_API_URL}/${d.categoryImage}` : no_img} 
                      />
                      <Link className="details-btn" to={`/productList/${d._id}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} viewBox="0 0 12 12">
                          <path d="M10.0035 3.40804L1.41153 12L0 10.5885L8.59097 1.99651H1.01922V0H12V10.9808H10.0035V3.40804Z">
                          </path>
                        </svg>
                      </Link>
                    </div>
                  </div>
                  <div className="portfolio-content">
                    <h4>
                      <Link to={`/productList/${d._id}`}>{d.categoryName}</Link>
                    </h4>
                  </div>
                </div>
              </Col>
            ))}
          </Row>

          {/* Show More/Show Less Button */}
        
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProductListing;
