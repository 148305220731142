import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import Breadcrumb from "../Component/BradeCrum";
import Category from "../Component/Category";
import { BsFilePdfFill } from "react-icons/bs";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import no_img from "../assets/Img/no-image.jpg";
import { ProductContext } from "../Context/ProductContext";
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import { BCNContext } from "../Context/BreadcrumName";
import HeaderMobile from "../Component/MobileHader";
import ProductBCrumb from "../Component/ProductBCrumb";


const allProdutUrl = {name : "Product Categories", url : "/All_Products"}

const ImageWithFallback = ({ src }) => {
  const [imgSrc, setImgSrc] = useState(src);

  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  const handleError = () => {
    setImgSrc(no_img);
  };

  return (
    <img 
      src={imgSrc} 
      alt='img' 
      onError={handleError}
    />
  );
};

const ProducDetials = () => {
  const {handleSet} = useContext(ProductContext)
  const navig = useNavigate();
  const [productData, setProductData] = useState({});
  const [selectedUnit, setSelectedUnit] = useState('Metric');
  const { pId } = useParams();
  const [quantity, setQuantity] = useState("");
  const [subsubCategoryData, setSubsubCategoryData] = useState([]);
  const {bcnHeader} = useContext(BCNContext);
  const [validQuant, setValidQuant] = useState(false);
  const [catDetail, setCatDetail] = useState({});
  const [subCatDetail, setSubCatDetail] = useState({});
  const [subSubCatDetail, setSubSubCatDetail] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_GRIPHOLD_API_URL}/api/auth/Allproducts/${pId}`);
        setProductData(res.data.data);
        const categoryRes = await axios.get(`${process.env.REACT_APP_GRIPHOLD_API_URL}/api/auth/all-sub-sub-category-by-sub-cat-id/${res.data.data.subCategoryID}`);
        setSubsubCategoryData(categoryRes.data.data);
        setSubCatDetail({name : res.data.data.subCategoryDetails.productSubCategory, url : `/productCategory/${res.data.data.subCategoryDetails._id}`})
        setSubSubCatDetail({name : res.data.data.subSubCategoryDetails.productSubSubCategory, url : `/productSubCategory/${res.data.data.subSubCategoryDetails._id}`})
        setCatDetail({name : res.data.data.categoryDetails.categoryName, url : `/productlist/${res.data.data.categoryDetails._id}`})
        console.log("iiiiiiiiiiiiiiiiiiiiiiii",res)
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
    window.scrollTo(0, 0);
  }, [pId]);

  const handleDownload = async () => {
    let uurl = productData.subCategoryDetails.productPDF;
    const url = `${process.env.REACT_APP_GRIPHOLD_API_URL}/${uurl}`;
    let dnFileName = uurl.split("/");
    dnFileName = dnFileName[dnFileName.length-1];

    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      const anchor = document.createElement('a');
      anchor.href = blobUrl;
      anchor.download = `${dnFileName}.pdf`; // specify filename here
      anchor.click();

      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };



  


      const handleAddToCart=()=>{
        if(Number(quantity) > 0){
          setValidQuant(false);
        handleClick();
        let preData = localStorage.getItem("cartItem");
        let stat = false;
        if (preData) {
          preData = JSON.parse(preData);
          preData = preData.map((it)=>{
            if(it.item._id == productData._id)
              {
                 let quant = Number(it.quantity) + Number(quantity)
                 let newData = {
                       item : productData,
                       quantity : quant
                 }
                 stat = true;
                 return newData;
              }
              else
              {
                return it;
              }
          })
          if(stat == false){
            preData = [...preData,{
              item : productData,
              quantity : quantity
            }]
          }
          
          handleSet(preData)
          localStorage.setItem('cartItem', JSON.stringify(preData));
          setQuantity("")
        navig("/inquiry")
        }
        else
        {
          localStorage.setItem('cartItem', JSON.stringify([{
            item : productData,
            quantity
          }]));
          handleSet([{
            item : productData,
            quantity
          }])
          setQuantity("")
        navig("/inquiry")
        }
      }
      else
      {
        setValidQuant(true);
      }
      }






      const [open, setOpen] = React.useState(false);

      const handleClick = () => {
        setOpen(true);
      };
    
      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };
    
      const action = (
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            {/* <CloseIcon fontSize="small" /> */}
          </IconButton>
        </React.Fragment>
      );
      const renderSpecifications = () => {
        if (selectedUnit === 'Imperial') {
          return Object.entries(productData.imperialValue || {}).map(([key, value], index) => {
            if(value && value.length > 0)
            {
              return(        
              <tr key={index}>
              <td data-th={key}>{key}</td>
               <td data-th={value}>{value}</td>
              </tr>
              )
            }
        });
        } else {
          return Object.entries(productData.specifications || {}).map(([key, value], index) => {
            if(value && value.length > 0)
              {
                return(        
                  <tr key={index}>
                  <td data-th={key}>{key}</td>
                  <td data-th={value}>{value}</td>
                  </tr>
                )
              }

        });
        }
      };

  return (
    <React.Fragment>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        message="Product added successfully"
        action={action}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <HeaderMobile/>
      {/* <Breadcrumb midTitle={bcnHeader} heading={productData.productName} bTitle={productData.subSubCategoryDetails?productData.subSubCategoryDetails.productSubSubCategory:""}/> */}
      <ProductBCrumb heading={productData.productName}  allProductLink={allProdutUrl} pcList={subCatDetail} pList={catDetail} pcsList={subSubCatDetail}/>
      <div className="product-table">
        <Container>
          <Row>
            <Col lg={8} className="col-12">
              <Row>
                <Col lg={5} sm={6} className="col-12">
                  <div className="img-sticky">
                    {/* <img src={`${process.env.REACT_APP_GRIPHOLD_API_URL}/${productData.productImage}`} className="w-100" alt="Product" /> */}
                    <ImageWithFallback 
          src={productData.productImage ? `${process.env.REACT_APP_GRIPHOLD_API_URL}/${productData.productImage}` : no_img} 
        />
                  </div>
                </Col>
                <Col lg={7} sm={6} className="col-12">
                  <h2>
                    <span className="sub-range">{productData.productName}</span>
                  </h2>
                  <h5 className="sub-head-product-single">
                  {productData.subSubCategoryDetails?productData.subSubCategoryDetails.productSubSubCategory:""}
                  </h5>
                  <p>{productData.shortDescription}</p>
                </Col>
                <Col lg={12} className="col-12">
                  <div className="product-details-page pt-30 pb-60">
                    <div className="product-description-and-review-area">
                      <Row>
                        <Col lg={12}>
                          <Tabs
                            defaultActiveKey="description"
                            id="product-details-tabs"
                            className="nav nav2 nav-pills"
                          >
                            <Tab eventKey="description" title="Description">
                              <div className="description">
                                <p dangerouslySetInnerHTML={{ __html: productData.description }} />
                              </div>
                            </Tab>
                            <Tab eventKey="specification" title="Specification">
                              <div className="unit-radio mb-20">
                                <p className="fw-bold fs-5">Units :</p>
                               
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault1"
                                  checked={selectedUnit === 'Metric'}
                                  onChange={() => setSelectedUnit('Metric')}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault1"
                                >
                                  Metric
                                </label>
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault2"
                                  checked={selectedUnit === 'Imperial'}
                                  onChange={() => setSelectedUnit('Imperial')}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault2"
                                >
                                  Imperial
                                </label>
                              </div>
                              <div className="row align-items-center">
                                <div className="col-lg-12 col-12">
                                  <table className="rwd-table single-product-spec-content table-responsive">
                                    <tbody>
                                    {renderSpecifications()}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </Tab>
                            <Tab eventKey="download" title="Downloads">
                              <table className="rwd-table">
                                <tbody>
                                  <tr>
                                    <th>Sr.No</th>
                                    <th>Product Image</th>
                                    {/*<th>Product Group Name</th>*/}
                                    <th>Product Name</th>
                                    <th>Download</th>
                                  </tr>
                                  <tr>
                                    <td data-th="Supplier Code">1</td>
                                    <td data-th="Supplier Name" className="spec-img">
                                      {/* <img src={`${process.env.REACT_APP_GRIPHOLD_API_URL}/${productData.productImage}`} alt="Product" /> */}
                                      <ImageWithFallback 
                                            src={productData.productImage ? `${process.env.REACT_APP_GRIPHOLD_API_URL}/${productData.productImage}` : no_img} 
                                       />
                                    </td>
                                    {/*<td data-th="Invoice Number">
                                      Slugging wrenches & Insert Sockets
                                    </td>*/}
                                    <td data-th="Invoice Date">
                                      {productData.productName} - {productData.subSubCategoryDetails?.productSubSubCategory}
                                    </td>
                                    <td data-th="Due Date">
                                      <div className="download-catalogue-btn">
                                        <Link to="#" onClick={handleDownload}>
                                          <BsFilePdfFill className="bi-file-pdf" />
                                          <span>Catalogue</span>
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </Tab>
                          </Tabs>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={4} className="col-12">
              <div className="right-side-box">
                <h6 className="mrb-2">
                  <svg
                    width={7}
                    height={14}
                    viewBox="0 0 8 13"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M8 6.50008L0 0L5.09091 6.50008L0 13L8 6.50008Z" />
                  </svg>
                  Product Name
                </h6>
                <p className="mrb-15"> {productData.productName}</p>
                <h6 className="mrb-2">
                  <svg
                    width={7}
                    height={14}
                    viewBox="0 0 8 13"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M8 6.50008L0 0L5.09091 6.50008L0 13L8 6.50008Z" />
                  </svg>
                  Sub Product Name
                </h6>
                <p className="mrb-15">
                  {productData.productName} - {productData.subSubCategoryDetails?.productSubSubCategory}
                </p>
                <h6 className="mrb-2">
                  <svg
                    width={7}
                    height={14}
                    viewBox="0 0 8 13"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M8 6.50008L0 0L5.09091 6.50008L0 13L8 6.50008Z" />
                  </svg>
                  Quantity
                </h6>
                {/* <form> */}
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-inner">
                        <input type="number" placeholder="Enter Quantity" onChange={(e)=>setQuantity(e.target.value)}/>
                        <br /><br />
                        {validQuant && <p style={{color:"red"}}>Please enter valid quantity.</p>}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-inner text-center" style={{cursor:"pointer"}}>
                        <Link
                          to="#"
                          className="primary-btn3 btn-hover"
                          // type="submit"
                          onClick={handleAddToCart}
                        >
                          Inquiry
                          <svg
                            width={12}
                            height={12}
                            viewBox="0 0 12 12"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M10.0035 3.40804L1.41153 12L0 10.5885L8.59097 1.99651H1.01922V0H12V10.9808H10.0035V3.40804Z"></path>
                          </svg>
                          <span
                            style={{ top: "56.3906px", left: "-5.54688px" }}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                {/* </form> */}
              </div>
            </Col>
          </Row>
          {subsubCategoryData.length > 0 &&
                <Category
                  Title="Other Product Category"
                  subsubCategoryData={subsubCategoryData}
                  bUrl="/productSubCategory/"
                />
              }
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ProducDetials;
