import React, { useEffect, useState } from "react";
import AboutUsImg from "../assets/Img/work-portfolio-2.jpg";
import Follows from "../Component/Follows";
import { MdOilBarrel, MdEngineering } from "react-icons/md";
import { FaIndustry } from "react-icons/fa";
import {
  GiChemicalTank,
  GiSteelClaws,
  GiPowerGenerator,
  GiMining,
  GiEggDefense,
} from "react-icons/gi";
import { FaOilWell } from "react-icons/fa6";
import { TbBuildingFactory2 } from "react-icons/tb";
import Breadcrumb from "../Component/BradeCrum";

import { Card, Col, Row } from "react-bootstrap";
import axios from "axios";
import { useParams } from "react-router-dom";
import HeaderMobile from "../Component/MobileHader";
const bcmh = {name : ""}
const AboutUs = () => {
  const {title} = useParams();
  const [content, setContent] = useState([]);

  useEffect(()=>{
    axios.get(`${process.env.REACT_APP_GRIPHOLD_API_URL}/api/auth/getCMS/cmss/${title}`)
    .then((res)=>
      setContent(res.data)
    )
    .catch((err)=>
      console.log(err)
    )
    window.scrollTo(0, 0);
  },[])
  const companyData = [
    {
      title: "Petrochemicals",
      Icon: <MdOilBarrel />,
    },
    {
      title: "Cement Plants",
      Icon: <FaIndustry />,
    },
    {
      title: "Chemicals,Fertilizers",
      Icon: <GiChemicalTank />,
    },
    {
      title: "Iron and Steel, Refineries Ports",
      Icon: <GiSteelClaws />,
    },
    {
      title: "Oil and Gas Exploration",
      Icon: <FaOilWell />,
    },
    {
      title: "Ship Building Industries",
      Icon: <TbBuildingFactory2 />,
    },
    {
      title: "Mining",
      Icon: <GiMining />,
    },
    {
      title: "Defense Establishments",
      Icon: <GiEggDefense />,
    },
    {
      title: "Automobiles,Power Generation",
      Icon: <GiPowerGenerator />,
    },
    {
      title: "Railways and many other engineering industries",
      Icon: <MdEngineering />,
    },
  ];
  return (
    <React.Fragment>
      <HeaderMobile/>
      <Breadcrumb heading={"About Us"} bTitle={"About Us"}/>
      <div class="page-content">
        {/* <div class="line_wrap">
          <div class="line_item first-line"></div>
          <div class="line_item center-line"></div>
          <div class="line_item last-line"></div>
        </div> */}
        <div className="about-banner-section">
          <div className="container-lg container-fluid">
            <div className="about-content-wrap">
              <div className="sub-title text-animation">
                <h6>
                  About Us
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={50}
                    height={6}
                    viewBox="0 0 50 6"
                  >
                    <path d="M50 3L45 0.113249V5.88675L50 3ZM0 3.5H45.5V2.5H0V3.5Z" />
                  </svg>
                </h6>
                <svg
                  className="star"
                  xmlns="http://www.w3.org/2000/svg"
                  width={45}
                  height={36}
                  viewBox="0 0 45 36"
                >
                  <path d="M41.0652 13.6409C35.2969 11.1608 31.0567 6.12526 29.8725 0C28.6882 6.12526 24.448 11.1608 18.6798 13.6409C17.5719 14.1294 14.7451 14.881 14.7451 14.881C14.7451 14.881 17.5337 15.6326 18.6798 16.1211C24.448 18.6013 28.6882 23.6743 29.8725 29.762C31.0567 23.6367 35.2969 18.6013 41.0652 16.1211C42.173 15.6326 44.9998 14.881 44.9998 14.881C44.9998 14.881 42.2112 14.1294 41.0652 13.6409Z" />
                  <path d="M17.1903 26.4929C13.7523 25.0274 11.2693 22.0211 10.5435 18.4136C9.85588 22.0211 7.33466 25.0274 3.89663 26.4929C3.24722 26.7935 1.56641 27.2069 1.56641 27.2069C1.56641 27.2069 3.20902 27.6578 3.89663 27.9209C7.33466 29.3864 9.81768 32.3927 10.5435 36.0002C11.2693 32.4303 13.7523 29.424 17.1903 27.9585C17.8398 27.6578 19.5206 27.2445 19.5206 27.2445C19.5206 27.2445 17.8398 26.7935 17.1903 26.4929Z" />
                  <path d="M44.3893 27.2443C44.3893 28.1086 43.6635 28.785 42.823 28.785C41.9444 28.785 41.2568 28.071 41.2568 27.2443C41.2568 26.38 41.9826 25.7036 42.823 25.7036C43.6635 25.666 44.3893 26.38 44.3893 27.2443Z" />
                  <path d="M15.318 5.8244C15.318 6.6887 14.5922 7.36511 13.7518 7.36511C12.8732 7.36511 12.1855 6.65112 12.1855 5.8244C12.1855 4.9601 12.9114 4.28369 13.7518 4.28369C14.6304 4.28369 15.318 4.9601 15.318 5.8244Z" />
                  <path d="M1.56621 16.4218C2.43121 16.4218 3.13243 15.732 3.13243 14.881C3.13243 14.0301 2.43121 13.3403 1.56621 13.3403C0.701218 13.3403 0 14.0301 0 14.881C0 15.732 0.701218 16.4218 1.56621 16.4218Z" />
                </svg>
              </div>
              {/* <div className="about-section-title text-animation">
                <p>
                  <span className="gripholdTitle">GRIPHOLD</span> is a force to
                  reckon with in the Indian as well as Iternational markets. We
                  offer a complete bolting solution portfolio right from
                  manufacturing, exporting, renting and bespoke product
                  development. What makes us a frontrunner in this business is
                  our commitment to providing smart solutions and quality of
                  service.
                </p>
              </div> */}

              {/* <div className="industrialDiv">
              <h3>
                Our wide ranging array of torque tools caters to the varied and
                complex needs of core sector industries including:
              </h3>
              <Row>
                {companyData?.map((d) => (
                  <Col lg={4}>
                    <Card className="industrialCard">
                      <div className="companyTitle">
                        {d.Icon} {d.title}
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
              <p>
                We have a strong presence in the torque tools market for the
                past 29 years. Over the years Griphold has carved a niche for
                itself with international quality control and best practice. The
                Company prides itself for its commitment to producing the most
                robust, durable and reliable equipment.
              </p>
              <p>
                Innovative product development and creative solution-oriented
                approach are the hallmark of our practice. We have thus
                established ourselves as an industry leader in the Torque Tools
                and High-Pressure Hydraulic tools business. Our product bouquet
                has multifarious offerings such as:
              </p>
              <p>
                We are also equipped with state-of-the-art infrastructure for
                manufacturing and in-house product development. Our product
                designing expertise meets the challenging and complex products
                needs of various industries.
              </p>
              </div> */}
              {content.length > 0 &&<div
          dangerouslySetInnerHTML={{ __html: `<style>tr { background-color: #f0f0f0 !important; border-bottom: none; }</style> ${content[0].Description}` }}>
        </div>}
              <Follows />
            </div>
          </div>
        </div>
      </div>
     
    </React.Fragment>
  );
};

export default AboutUs;
